<template>
    <div class="position-relative">
        <section class="blockElement performance-traders-iframe pt-4 tab-content ">
            <div class="container-fluid mb-4">
                <div class="row">
                    <div class="col-12">
                        <div class="card-body bg-white border boxed d-flex align-items-center mb-2" v-for="item, index in LeaderData?.result" :key="index">
                            
                            <div class="d-flex gridCol1" v-if="item.trader && Object.keys(item.trader).length">
                                <div class="position-relative largeIcon">
                                    <span class="top-avtar w-17 h-17 d-block">
                                        <v-lazy-image
                                            width="100"
                                            class="d-block"
                                            :src="`/assets/images/country_flag/${item.trader.profile.countryName.toLowerCase()}.webp`"
                                            :alt="item.trader.profile.countryName"
                                            :title="item.trader.profile.countryName"
                                        />
                                    </span>
                                    <span class="gridImgCircle d-block rounded-circle">
                                        <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`" class="text-main" title="FastWaySignal">
                                            <v-lazy-image
                                                class="rounded-circle"
                                                :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                                alt="FastWaySignal"
                                                title="North East Way EA."
                                            />
                                        </router-link>
                                    </span>
                                </div>
                                <div class="ps-3 ps-md-3" v-if="item.trader.profile && Object.keys(item.trader.profile).length">
                                    <router-link :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`" class="text-main" title="FastWaySignal" v-if="item.trader.profile.name">
                                        <h4 class="mb-0 f-17">{{ item.trader.profile.name }}</h4>
                                    </router-link>
                                    <!-- <div class="smallIcom">
                                        <span>
                                            <v-lazy-image src="/assets/images/logo.svg" alt="Other Broker" :title="item.trader.profile.brokerName" height="15" />
                                        </span>
                                    </div> -->
                                    <!-- <div class="tooltipbutton" v-if="item.trader.profile.strategyDesc">
                                        <span class="tooltiptext left tooltiptextleader">
                                            <p class="f-12 medium mb-1 flex-between">{{$t('view_all_leaders.strategyDescription')}} <a href="javascript:void(0)">{{$t('view_all_leaders.translate')}}</a></p>
                                            <p class="f-12">{{ item.trader.profile.strategyDesc }}</p>
                                        </span>
                                        <p class="f-12 mb-0">{{ item.trader.profile.strategyDesc.substr(0, 100) + '...' }}</p>
                                    </div> -->
                                    <div class="">
                                        <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" :title="$t('view_all_leaders.theSumofTheInvested')">{{$t('view_all_leaders.amountFollowing')}}</a>
                                        <h4 class="f-14 mb-0 bold" v-if="item.trader.overallStats && item.trader.overallStats.amountFollowing">
                                            ${{ Math.round(item.trader.overallStats.amountFollowing).toLocaleString() }}
                                        </h4>
                                        <h4 class="f-14 mb-0 bold" v-else>$0</h4>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="charBlock gridCol2 px-3">   
                                <div :id="'allTraderPerformace' + item?.trader?.providerId" class="commonchart"></div>
                            </div>
                            <div class="charBlock gridCol3 p-0">
                                <table class="trader-listtd w-100">
                                    <tr>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="Pips">Pips</a>
                                                <h4 class="f-14 mb-0 bold">
                                                    {{item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]]?.currencyStats?.[0]?.pips >= 0 ? '' : '-'}}${{ Math.abs(Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]]?.currencyStats?.[0]?.pips || 0)).toLocaleString()  || 0}}
                                                </h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" :title="$t('view_all_leaders.theNumberOfLive')">Weeks</a>
                                                <h4 class="f-14 mb-0" v-if="item.trader.overallStats.weeks">
                                                    {{ Math.round(item.trader.overallStats.weeks) }}
                                                </h4>
                                                <h4 class="f-14 mb-0" v-else>0</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="Max DD">Max DD%</a>
                                                <h4 class="f-14 mb-0" v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].maxDrawDownPercent">
                                                    {{ Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].maxDrawDownPercent) }}%
                                                </h4>
                                                <h4 class="f-14 mb-0" v-else>0%</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" :title="$t('view_all_leaders.theNumberOfLive')">{{$t('view_all_leaders.inverstor')}}</a>
                                                <h4 class="f-14 mb-0" v-if="item.trader.overallStats && item.trader.overallStats.followers">
                                                    {{ item.trader.overallStats.followers.toLocaleString() }}
                                                </h4>
                                                <h4 class="f-14 mb-0" v-else>0</h4>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="Trades">Trades</a>
                                                <h4 class="f-14 mb-0 bold">
                                                    ${{ Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].trades).toLocaleString() || 0 }}
                                                </h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="">
                                                    Win%
                                                </a>
                                                <h4
                                                    class="f-14 mb-0 secondary"
                                                    v-if="item.trader.timeframeStats && Object.keys(item.trader.timeframeStats).length && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].winTrades"
                                                >
                                                    {{ Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].winTrades) }}%
                                                </h4>
                                                <h4 class="f-14 mb-0 secondary" v-else>0%</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="">
                                                <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="">
                                                    ROI
                                                </a>
                                                <h4
                                                    class="f-14 mb-0 secondary"
                                                    v-if="item?.trader?.overallStats?.roiAnnualized"
                                                >
                                                    {{ Math.round(item.trader.overallStats.roiAnnualized) }}%
                                                </h4>
                                                <h4 class="f-14 mb-0 secondary" v-else>0</h4>
                                            </div>
                                        </td>
                                        <td colspan="2" style="vertical-align: middle;">
                                            <div class="d-flex align-items-center">
                                                <router-link :to="`/trader/${item.trader.providerId}/trading`" class="button fillBtn linkBtn f-10 px-md-4 me-2" href="javascript:void(0)">
                                                    {{$t('view_all_leaders.follow')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="flex-between mt-2">
                            <router-link to="/"><img width="100" height="18" src="/assets/images/logo.svg" alt="Logo"> </router-link>
                            <router-link to="/leaders" href="javascript:void(0)" class="secondary underline f-14"> Show more Traders </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            return { store: myStore() };
        },
        data() {
            return {
                LeaderData: {},
            };
        },
        methods: {
            drawprofitChart(id, chartData) {
                let ID = "allTraderPerformace" + id;
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === ID);
                d?.dispose();
                let root = am5.Root.new(ID);

                root.interfaceColors.set("grid", am5.color(0xffffff));

                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
                root.numberFormatter.setAll({
                    numberFormat: "#a",
                    smallNumberThreshold: 0.001,
                });

                var chart = root.container.children.push(am5xy.XYChart.new(root, { focusable: true,paddingLeft: 0,paddingRight: 0,paddingBottom: 0 }));

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        groupData: false,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {}),
                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });

                chart.get("colors").set("colors", [am5.color(0xffa65e)]);
                // Add series
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "{valueX.formatDate('dd MMM yyyy')}: {valueY}",
                        }),
                    })
                );
                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.2,
                            },
                            {
                                opacity: 0.2,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set up data processor to parse string dates
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: "yyyy-MM-dd",
                    dateFields: ["y"],
                });
                // Add cursor
                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                xAxis.hide();

                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                    fontSize: 12,
                });

                // Set data
                series.data.setAll(chartData);

                // Make stuff animate on load
                series.appear(300);
                chart.appear(300, 100);
            },
        },
        created() {
            this.store.getFlavorID({}, true).then((response) =>{
                if(response?.flavorId){
                    let form = {
                        accessingFlavorId: response?.flavorId,
                        bringDetailedChart: true,
                        flavor: response?.isEu ? "eu" : "global",
                        size: this.$route.query?.size || 4,
                        sortAsc: this.$route.query?.sortAsc || false,
                        sortBy: this.$route.query?.sortBy || "zulurank",
                        timeFrame: this.$route.query?.view || 30,
                    };
                    // if(this.$route.query?.base){
                    //     form['base'] = this.$route.query?.base
                    // }
                    this.store.getTradersFlavorWise(form, true).then((response) => {
                        if (response?.result && response?.result.length) {
                            this.LeaderData = response;
                            if(!this.$route.query?.charts || this.$route.query?.charts == 'true'){
                                setTimeout(() => {
                                    response?.result.forEach(i=>{
                                        if(i?.trader?.timeFrameGraph?.series?.[0]?.data){
                                            this.drawprofitChart(i?.trader?.providerId, i?.trader?.timeFrameGraph?.series?.[0]?.data);
                                        }
                                    })
                                },300);
                            }
                        } else {
                            this.LeaderData = {};
                        }
                    });
                }
            })
        },
    };
</script>
<style>
.commonchart {
    height: 60px;
    width: 150px;
}
</style>
